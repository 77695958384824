import { login } from '@elysium-xr/js-sdk/lib/directus'
import { NotAuthorizedError } from '@elysium-xr/js-sdk/types'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Loading } from '../Loading'
import ValueListProvider, { ErrorsDict, ValuesDict } from '../ValueListProvider'
import { Button } from './Button'
import { Form } from './Form'
import { Input } from './Input'
import { isValidEmail } from '../../util'

const initialFormState = {
  email: '',
  password: '',
}

export const Login = () => {
  const [values, setValues] = useState<ValuesDict>(initialFormState)
  const [error, setError] = useState<string | null>(null)
  const [errors, setErrors] = useState<ErrorsDict>({})
  const [pending, setPending] = useState<boolean>(false)

  const onSubmit = async () => {
    setError(null)
    setErrors({})
    console.log('[elysium-web-app] Login.onSubmit', values)
    const { email, password } = values

    if (!email || !password) {
      return
    }

    // validate email
    if (!isValidEmail(email)) {
      setErrors({ email: true })
      setError('Please enter a valid email')
      return
    }

    setPending(true)

    try {
      const res = await login(email, password)
      console.log('[Login] access token', res)
      const _rnwv = (window as any).ReactNativeWebView
      if (_rnwv) {
        _rnwv.postMessage(res.data?.access_token)
      }
    } catch (ex) {
      if (ex instanceof NotAuthorizedError) {
        setError('Wrong email or password')
      } else {
        setError('Cannot login at this time')
      }
    }
    setPending(false)
  }
  const onChange = (values: ValuesDict) => {
    setValues(values)
  }
  return (
    <ValueListProvider
      values={values}
      onChange={onChange}
      onSubmit={onSubmit}
      errors={errors}
    >
      <Form title="Sign in to Elysium">
        <div className="-space-y-px">
          <Input
            label="Email"
            path="email"
            type="text"
            adjoin="bottom"
            required
          />
          <Input
            label="Password"
            path="password"
            type="password"
            adjoin="top"
            required
          />
          {error && <div className="text-error">🛑 {error}</div>}
          <div className="text-sm pt-2">
            <Link to="/forgot" className="text-link">
              Forgot your password?
            </Link>
          </div>
        </div>

        <Button type="submit" onClick={onSubmit} disabled={pending}>
          {pending ? <Loading /> : 'Sign in'}
        </Button>

        <div className="text-sm font-light">
          Don't have an account?{' '}
          <Link className="text-link" to="/signup">
            Sign up
          </Link>
        </div>
      </Form>
    </ValueListProvider>
  )
}
