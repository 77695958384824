import { createRoot } from 'react-dom/client'
import { ElysiumProvider } from '@elysium-xr/js-sdk/react/ElysiumProvider'
import './index.css'
import { App } from './components/App'
import { BrowserRouter } from 'react-router-dom'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <ElysiumProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ElysiumProvider>
)
