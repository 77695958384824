import qs from 'qs'
import { InvalidPayloadError, UnexpectedServerError } from '../types/errors'
import request, { RequestOptions } from './request'

export interface DirectusAuthResponse {
  access_token: string
  refresh_token: string
  expires: number
}

export interface DirectusSignupResponse {
  id: string
}

export interface DirectusErrorResponse {
  message: string
  extensions: {
    code: string
    collection: string
    field: string
    invalid: string
  }
}

export interface DirectusResponse<T> {
  data?: T
  errors?: [DirectusErrorResponse]
}

export let authResponse: DirectusAuthResponse | null = null

export async function checkNetwork() {
  if (process.env.NODE_ENV === 'test') {
    return true
  }
  try {
    const _res = await request(process.env.DIRECTUS_URL!, {
      method: 'HEAD',
      rawResponse: true,
    })
    if (!(_res && (_res.ok || _res.type === 'opaque'))) {
      return false
    }
  } catch (err) {
    return false
  }
  return true
}

interface QueuedRequest {
  resolve: (value: any) => void
  reject: (reason: any) => void
  uri: string
  options: RequestOptions
}
const requestQ: QueuedRequest[] = []

setInterval(() => {
  if (!requestQ.length) {
    return
  }
  const _req = requestQ.shift()!
  request(_req.uri, _req.options).then(_req.resolve).catch(_req.reject)
}, 250)

/**
 * Wrapper for Directus API calls that populates the authorization headers
 * and parses the response as JSON
 * @param path Raw path to the endpoint, appended to the host
 * @param options WHATWG fetch request options
 */
export const requestDirectus = <T>(
  path: string,
  options: RequestOptions = { headers: {} }
) => {
  const _auth = getLocalDirectusAuth()
  const _uri = `${process.env.DIRECTUS_URL}/${
    path.startsWith('/') ? path.substring(1) : path
  }`
  const _options: RequestOptions = {
    timeout: 600000,
    ...options,
    headers: {
      ...options.headers,
      ...(_auth
        ? {
            Authorization: `Bearer ${_auth.access_token}`,
          }
        : {}),
    },
    checkStatusFirst: (response) => {
      if (response.status === 400) {
        return new Promise((resolve, reject) => {
          response
            .json()
            .then((errorResponse: DirectusResponse<DirectusErrorResponse>) => {
              if (errorResponse.errors?.length) {
                reject(
                  new InvalidPayloadError(
                    errorResponse.errors[0].message,
                    errorResponse.errors[0].extensions
                  )
                )
              } else {
                reject(new UnexpectedServerError(response.statusText, response))
              }
            })
            .catch(() => {
              reject(new UnexpectedServerError(response.statusText, response))
            })
        })
      }
      return response
    },
  }
  return new Promise<DirectusResponse<T>>((resolve, reject) => {
    requestQ.push({ resolve, reject, uri: _uri, options: _options })
  })
}

export const postDirectus = async <T>(
  path: string,
  body: any,
  options: RequestOptions = {}
) =>
  requestDirectus<T>(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    ...options,
  })

export function clearLocalDirectusAuth() {
  localStorage.removeItem('directusAuth')
  window.dispatchEvent(new Event('local-storage'))
}

export function setLocalDirectusAuth(auth: DirectusAuthResponse) {
  localStorage.setItem('directusAuth', JSON.stringify(auth))
  window.dispatchEvent(new Event('local-storage'))
}

export function getLocalDirectusAuth(): DirectusAuthResponse | null {
  const _authJson = localStorage.getItem('directusAuth')
  if (_authJson) {
    return JSON.parse(_authJson)
  }
  return null
}

export function login(email: string, password: string) {
  clearLocalDirectusAuth()
  return postDirectus<DirectusAuthResponse>('/auth/login', {
    email,
    password,
  })
}

export function signUp(email: string, password: string) {
  clearLocalDirectusAuth()
  return postDirectus<DirectusSignupResponse>('/users', {
    email,
    password,
  })
}

export function sendResetEmail(email: string) {
  return postDirectus('/auth/password/request', {
    email,
    reset_url: `${process.env.WEB_APP_URL}/reset-password`,
  })
}

export function resetPassword(token: string, password: string) {
  return postDirectus('/auth/password/reset', {
    token,
    password,
  })
}

export function handleAuthCallback() {
  if (!window.location.hash) {
    return false
  }
  const hash = qs.parse(window.location.hash.substring(1))
  /* eslint-disable-next-line no-restricted-globals */
  history.replaceState(
    {},
    '',
    window.location.href.replace(window.location.hash, '')
  )
  if (
    !hash['directus_access_token'] ||
    !hash['directus_refresh_token'] ||
    !hash['directus_expires']
  ) {
    return false
  }
  authResponse = {
    access_token: hash['directus_access_token'] as string,
    refresh_token: hash['directus_refresh_token'] as string,
    expires: parseInt(hash['directus_expires'] as string, 10),
  }
  console.log('[Directus] auth response', authResponse)
  return true
}
