import './styles.css'

export interface FormProps extends React.HTMLAttributes<HTMLFormElement> {
  title: string
  description?: string
}

export const Form = ({
  title,
  children,
  description,
  ...others
}: React.PropsWithChildren<FormProps>) => {
  const onFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
  }
  return (
    <form
      className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      onSubmit={onFormSubmit}
      {...others}
    >
      <div className="max-w-sm w-full space-y-8 bg-slate-100 p-8 rounded-md">
        <div>
          <img
            className="mx-auto h-14 w-auto"
            src="img/logo-icon.png"
            alt="Elysium"
          />
          <h2 className="mt-6 text-center text-3xl text-gray-900">{title}</h2>
          {description && <p className="text-sm mt-4">{description}</p>}
          <div className={`${description ? 'mt-6' : 'mt-8'} space-y-6`}>
            {children}
          </div>
        </div>
      </div>
    </form>
  )
}
