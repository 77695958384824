import {
  DirectusResponse,
  DirectusSignupResponse,
  login,
  signUp,
} from '@elysium-xr/js-sdk/lib/directus'
import { InvalidPayloadError } from '@elysium-xr/js-sdk/types/errors'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { isValidEmail } from '../../util'
import { Loading } from '../Loading'
import ValueListProvider, { ErrorsDict, ValuesDict } from '../ValueListProvider'
import { Button } from './Button'
import { Form } from './Form'
import { Input } from './Input'

const initialFormState = {
  email: '',
  password: '',
}

export const SignUp = () => {
  const [values, setValues] = useState<ValuesDict>(initialFormState)
  const [errors, setErrors] = useState<ErrorsDict>({})
  const [error, setError] = useState<string | null>(null)
  const [pending, setPending] = useState<boolean>(false)

  const onSubmit = async (e: any) => {
    setErrors({})
    setError(null)

    if (!e.target.form.checkValidity()) {
      return
    }
    const { email, password } = values
    if (!email || !password) {
      return
    }

    // validate email
    // TODO: also do this server-side in elysium-api extension
    if (!isValidEmail(email)) {
      setErrors({ email: '🛑 Please enter a valid email' })
      return
    }

    setPending(true)

    let signupResponse: DirectusResponse<DirectusSignupResponse> | null = null

    try {
      signupResponse = await signUp(email, password)
    } catch (ex) {
      if (ex instanceof InvalidPayloadError) {
        if (ex.details?.code === 'RECORD_NOT_UNIQUE') {
          setErrors({ email: '🛑 Already an account with that email' })
        } else if (
          ex.details?.field === 'password' &&
          ex.details?.code === 'FAILED_VALIDATION'
        ) {
          setErrors({
            password: '🛑 Password must be at least 8 characters long',
          })
        } else {
          setError('Cannot sign up at this time')
        }
      } else {
        setError('Cannot sign up this time')
      }
    }

    if (signupResponse) {
      try {
        const loginResponse = await login(email, password)
        console.log('[SignUp] access token', loginResponse.data?.access_token)
        const _rnwv = (window as any).ReactNativeWebView
        if (_rnwv) {
          _rnwv.postMessage(loginResponse.data?.access_token)
        }
      } catch (ex) {
        setError('Cannot sign up at this time')
      }
    }

    setPending(false)
  }
  const onChange = (values: ValuesDict) => {
    setValues(values)
  }
  return (
    <ValueListProvider
      values={values}
      onChange={onChange}
      onSubmit={onSubmit}
      errors={errors}
    >
      <Form title="Create your account">
        <div className="space-y-4">
          <Input label="Email" path="email" type="text" required />
          <Input label="Password" path="password" type="password" required />
          {error && <div className="text-error">🛑 {error}</div>}
        </div>

        <Button type="submit" onClick={onSubmit} disabled={pending}>
          {pending ? <Loading /> : 'Sign up'}
        </Button>

        <div className="text-sm font-light">
          Already have an account?{' '}
          <Link className="text-link" to="/">
            Sign in
          </Link>
        </div>
      </Form>
    </ValueListProvider>
  )
}
