import { useState } from 'react'
import { isValidEmail } from '../../util'
import ValueListProvider, { ErrorsDict, ValuesDict } from '../ValueListProvider'
import { Form } from './Form'
import { Input } from './Input'
import { Button } from './Button'
import { Loading } from '../Loading'
import { Link } from 'react-router-dom'
import { InvalidPayloadError } from '@elysium-xr/js-sdk/types/errors'
import { sendResetEmail } from '@elysium-xr/js-sdk/lib/directus'

export const Forgot = ({ email }: { email?: string }) => {
  const [values, setValues] = useState<ValuesDict>({ email: email ?? '' })
  const [errors, setErrors] = useState<ErrorsDict>({})
  const [error, setError] = useState<string | null>(null)
  const [pending, setPending] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const onSubmit = async (e: any) => {
    console.log('[Forgot] onSubmit', values)
    if (!e.target.form.checkValidity()) {
      return
    }
    setErrors({})
    setError(null)

    const { email } = values

    if (!isValidEmail(email)) {
      setErrors({ email: '🛑 Please enter a valid email' })
      return
    }

    setPending(true)

    try {
      await sendResetEmail(email)
      setSuccess(true)
    } catch (ex) {
      if (ex instanceof InvalidPayloadError) {
        console.log('[Forgot] InvalidPayloadError', JSON.stringify(ex))
        setError('Cannot send email at this time')
      } else {
        console.log('[Forgot] error', JSON.stringify(ex))
        setError('Cannot send email at this time')
      }
    }

    setPending(false)
  }

  return (
    <ValueListProvider
      values={values}
      onChange={setValues}
      onSubmit={onSubmit}
      errors={errors}
    >
      <Form
        title={success ? 'Check your email' : 'Forgot your password?'}
        description={
          success
            ? undefined
            : "No worries, we'll send you an email with a link to reset it."
        }
      >
        {success ? (
          <div className="space-y-4 text-sm mb-5 text-center">
            We sent a reset password link to {values.email}.
          </div>
        ) : (
          <>
            <div className="space-y-4">
              <Input label="Email" path="email" type="text" required />
              {error && <p className="text-error">🛑 {error}</p>}
            </div>

            <Button type="submit" onClick={onSubmit} disabled={pending}>
              {pending ? <Loading /> : 'Send email'}
            </Button>

            {/* <div className="text-sm font-light">
              Remembered your password?{' '}
              <Link className="text-link" to="/">
                Sign in
              </Link>
            </div> */}
          </>
        )}
      </Form>
    </ValueListProvider>
  )
}
