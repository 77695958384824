import {
  ResourceNotFoundError,
  NotAuthorizedError,
  NetworkUnavailableError,
  InsufficientPermissionError,
  UnexpectedServerError,
} from '../types'

export const FETCH_TIMEOUT = parseInt(process.env.FETCH_TIMEOUT!, 10)

function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  if (response.status === 401) {
    throw new NotAuthorizedError('Unauthorized')
  }
  if (response.status === 403) {
    throw new InsufficientPermissionError()
  }
  if (response.status === 404) {
    throw new ResourceNotFoundError()
  }
  throw new UnexpectedServerError(response.statusText, response)
}

function parseResponse(
  responseAsJson?: boolean
): (response: Response) => Promise<any> {
  if (responseAsJson) {
    return (response) => response.json()
  }
  return (response) =>
    response.headers.get('Content-Type')?.match('json')
      ? response.json()
      : response.text()
}

// eslint-disable-next-line no-undef
export interface RequestOptions extends RequestInit {
  /**
   * Force response parsing to JSON.
   * If false, only parse as JSON if the Content-Type header is "json"
   */
  responseAsJson?: boolean
  /**
   * Number of MS to wait for a response from the server before timing out
   */
  timeout?: number
  /**
   * Return the raw response, no parse
   */
  rawResponse?: boolean
  /**
   * Check response callback, called before base checkResponse
   */
  checkStatusFirst?: (response: Response) => Promise<Response> | Response
}

function request(uri: string, options: RequestOptions = {}) {
  const {
    timeout,
    responseAsJson,
    rawResponse,
    checkStatusFirst,
    ..._options
  } = options
  const _controller = new AbortController()
  const _timeoutDuration = timeout || FETCH_TIMEOUT

  const _timeoutId = setTimeout(() => {
    _controller.abort()
    throw new NetworkUnavailableError(
      `Request timed out after ${_timeoutDuration}ms`
    )
  }, 15000)

  return fetch(uri, { ..._options, signal: _controller.signal })
    .catch((err) => {
      throw new NetworkUnavailableError(err)
    })
    .then((response) => {
      clearTimeout(_timeoutId)
      return response
    })
    .then(checkStatusFirst ?? ((response) => response))
    .then(checkStatus)
    .then((r) => {
      if (rawResponse) {
        return r
      }
      return parseResponse(responseAsJson)(r)
    })
}

export function getJson(uri: string) {
  return request(uri, { responseAsJson: true })
}

export default request
