import { useValueListContext, ValueListItem } from '../ValueListProvider'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  type: string
  path: string
  adjoin?: 'top' | 'bottom' | 'both'
}

export const Input = ({ label, type, adjoin, path, ...others }: InputProps) => {
  const { errors } = useValueListContext()

  const borderClasses =
    errors[path] && !adjoin ? 'border-red-600' : 'border-gray-300'

  const radiusClasses = `${
    adjoin !== 'top' && adjoin !== 'both' ? 'rounded-t-md' : ''
  } ${adjoin !== 'bottom' && adjoin !== 'both' ? 'rounded-b-md' : ''}`

  const labelClasses = errors[path] ? 'text-red-600' : 'text-gray-400'

  return (
    <div className="textinput">
      <div className="relative">
        <ValueListItem path={path}>
          <input
            type={type}
            placeholder=" "
            className={`relative block w-full px-3 pt-6 pb-2 border placeholder-gray-500 text-gray-900  focus:outline-none focus:z-10 focus:ring-indigo-500 focus:border-indigo-500 ${borderClasses} ${radiusClasses}`}
            {...others}
          />
        </ValueListItem>
        <label
          className={`absolute top-1/2 text-lg px-2 duration-300 origin-0 ${labelClasses}`}
        >
          {label}
        </label>
      </div>
      {errors[path] && typeof errors[path] === 'string' && (
        <p className="text-error">{errors[path]}</p>
      )}
    </div>
  )
}
