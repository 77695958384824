import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useCallback,
} from 'react'
import cloneDeep from 'clone-deep'
import _ from 'lodash'

/**
 * ValueListProvider creates a controlled (two-way) binding between an object
 * of shape { key0: value0, key1: value1, ... } and a context
 * that can be consumed by ValueListItem.
 * Values in the bound object can be literals, arrays or objects
 */
export type ValuesDict = { [key: string]: string }
export type ErrorsDict = { [key: string]: string | boolean }

interface ContextType {
  onItemChange: (path: string, value: string) => void
  onSubmit: (values: ValuesDict) => void
  values: ValuesDict
  valuesRef: React.MutableRefObject<ValuesDict>
  errors: ErrorsDict
}

export const ValueListContext = createContext<ContextType>(null!)
const defaultText = ''

interface PropsType {
  values: ValuesDict
  onChange: (values: ValuesDict) => void
  onSubmit: (values: ValuesDict) => void
  errors?: ErrorsDict
}

// values is dicitonary
export const ValueListProvider = ({
  children,
  values,
  onChange,
  onSubmit,
  errors,
}: React.PropsWithChildren<PropsType>) => {
  const valuesRef = useRef(values)
  valuesRef.current = values

  const onItemChange = useCallback(
    (path: string, value: string) => {
      const _value = _.get(valuesRef.current, path, defaultText)
      if (_.isEqual(_value, value)) {
        return
      }

      // apply changes to values, cloned to preserve immutability
      const _values = cloneDeep(valuesRef.current)

      // lodash.set:
      // Sets the value at path of object.
      // If a portion of path doesn't exist, it's created.
      // https://lodash.com/docs/4.17.15#set

      _.set(_values, path, value)

      // pass updated values to parent handler
      // also pass the path in case the handler wants to know where the change
      // occurred
      onChange(_values)
    },
    [valuesRef, onChange]
  )

  return (
    <ValueListContext.Provider
      value={{
        onItemChange,
        values,
        valuesRef,
        onSubmit,
        errors: errors ?? {},
      }}
    >
      {children}
    </ValueListContext.Provider>
  )
}
export const useValueListContext = () => useContext(ValueListContext)

/**
 * ValueListItem consumes a ValueListContext to bind values and
 * onChange of its child
 */
export const ValueListItem = ({
  children,
  path,
  ...others
}: React.PropsWithChildren<{ path: string }>) => {
  const { onItemChange, valuesRef, errors } = useValueListContext()

  const value = _.get(valuesRef.current, path, defaultText)

  // lodash.get:
  // Gets the value at path of object.
  // If the resolved value is undefined, the defaultValue is returned in its place.
  // https://lodash.com/docs/4.17.15#get

  return useMemo(
    () =>
      React.cloneElement(
        React.Children.only(children) as React.ReactElement<any>,
        {
          value,
          onChange: (evt: React.ChangeEvent<HTMLInputElement>) =>
            onItemChange(path, evt.target.value),
          'data-test-path': path,
          ...others,
        }
      ),
    [
      JSON.stringify(value),
      errors[path],
      //  TODO: an extra prop must be passed in order to cause a rerender in the item
      ...Object.values(others),
    ]
  )
}

export default ValueListProvider
