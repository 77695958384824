export class NetworkUnavailableError extends Error {
  constructor(message = 'Network Unavailable') {
    super()
    this.message = message
  }
}

export class UnexpectedServerError extends Error {
  info: any

  // eslint-disable-next-line default-param-last
  constructor(message = 'Unexpected Server Error', response: any) {
    super()
    this.message = message
    this.info = response
  }
}

export class NotAuthorizedError extends Error {
  constructor(message = 'Not Authorized') {
    super()
    this.message = message
  }
}

export class ResourceNotFoundError extends Error {
  constructor(message = 'Resource Not Found') {
    super()
    this.message = message
  }
}

export class InsufficientPermissionError extends Error {
  constructor(message = 'Not Authorized') {
    super()
    this.message = message
  }
}

export class OperationTimedOutError extends Error {
  constructor(message = 'Operation timed out') {
    super()
    this.message = message
  }
}

export interface InvalidPayloadDetails {
  code: string
  collection: string
  field: string
  invalid: string
}

export class InvalidPayloadError extends Error {
  details: InvalidPayloadDetails

  constructor(message: string, details: InvalidPayloadDetails) {
    super()
    this.message = message
    this.details = details
  }
}
