import { useDirectusAuth } from '@elysium-xr/js-sdk/react/hooks'
import { clearLocalDirectusAuth } from '@elysium-xr/js-sdk/lib/directus'
import { Routes, Route } from 'react-router-dom'
import { Login, SignUp } from './SignOn'
import { Forgot } from './SignOn/Forgot'
import { ResetPassword } from './SignOn/ResetPassword'

export const Private = () => {
  const directusAuth = useDirectusAuth()
  console.log('[elysium-web-app] directusAuth', directusAuth)

  const onSignOutClick = () => {
    clearLocalDirectusAuth()
  }

  return !directusAuth ? (
    <div>
      <Login />
    </div>
  ) : (
    <div>
      <button onClick={onSignOutClick}>Sign Out</button>
    </div>
  )
}

export const App = () => {
  let qs: { [key: string]: string | null } = window.location.search
    ?.replace('?', '')
    .split('&')
    .reduce(function (accum: { [key: string]: string }, curr) {
      var keyvalue = curr.split('=')
      accum[keyvalue[0]] = decodeURI(keyvalue[1])
      return accum
    }, {})

  return (
    <div className="viewport">
      <Routes>
        <Route path="/" element={<Private />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot" element={<Forgot />} />
        {qs && qs['token'] && (
          <Route
            path="/reset-password"
            element={<ResetPassword token={qs['token']!} />}
          />
        )}
      </Routes>
    </div>
  )
}
