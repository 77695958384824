import { useState } from 'react'
import ValueListProvider, { ErrorsDict, ValuesDict } from '../ValueListProvider'
import { Form } from './Form'
import { Input } from './Input'
import { Button } from './Button'
import { Loading } from '../Loading'
import { InvalidPayloadError } from '@elysium-xr/js-sdk/types/errors'
import { resetPassword } from '@elysium-xr/js-sdk/lib/directus'

export const ResetPassword = ({ token }: { token: string }) => {
  const [values, setValues] = useState<ValuesDict>({})
  const [errors, setErrors] = useState<ErrorsDict>({})
  const [error, setError] = useState<string | null>(null)
  const [pending, setPending] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const onSubmit = async (e: any) => {
    setErrors({})
    setError(null)

    console.log('[ResetPassword] onSubmit', values)
    if (!e.target.form.checkValidity()) {
      return
    }

    const { password, password2 } = values

    if (password !== password2) {
      setError('Passwords do not match')
      return
    }

    setPending(true)

    try {
      await resetPassword(token, password)
      setSuccess(true)
    } catch (ex) {
      if (ex instanceof InvalidPayloadError) {
        if (
          ex.details?.field === 'password' &&
          ex.details?.code === 'FAILED_VALIDATION'
        ) {
          setErrors({
            password: '🛑 Password must be at least 8 characters long',
          })
        } else {
          setError('Cannot reset password at this time')
        }
      } else {
        console.log('[ResetPassword] error', JSON.stringify(ex))
        setError('Reset token is invalid or expired')
      }
    }

    setPending(false)
  }

  return (
    <ValueListProvider
      values={values}
      onChange={setValues}
      onSubmit={onSubmit}
      errors={errors}
    >
      <Form title={success ? 'Password reset' : 'Reset your password'}>
        {success ? (
          <div className="space-y-4 text-sm mb-5 text-center">
            Success! You may now login on the app using the new password.
          </div>
        ) : (
          <>
            <div className="space-y-4">
              <Input
                label="New Password"
                path="password"
                type="password"
                required
              />
              <Input
                label="Confirm Password"
                path="password2"
                type="password"
                required
              />
              {error && <div className="text-error">🛑 {error}</div>}
            </div>

            <Button type="submit" onClick={onSubmit} disabled={pending}>
              {pending ? <Loading /> : 'Submit'}
            </Button>
          </>
        )}
      </Form>
    </ValueListProvider>
  )
}
